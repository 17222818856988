/* ContratoV3.js */
/* @author: anghello */

import React from 'react';
import './../styles/contrato.css';

const Title1 = ({ children }) => { return <p className="textCenter strong">{children}</p>; };
const P = ({ children }) => { return <p>{children}</p>; };
const Tab1 = ({ children }) => { return <p className='tab'>{children}</p>; };
const Tab2 = ({ children }) => { return <p className='tab2'>{children}</p>; };
const Tab3 = ({ children }) => { return <p className='tab3'>{children}</p>; };


function ContratoV8() {
  return (
    <div className="contrato">
      <Title1>CONTRATO DE PRESTAÇÃO DE SERVIÇOS E LICENÇA DE SOFTWARE</Title1>

      <P>GESTAUM CONTABILIDADE E GESTAO DIGITAL LTDA., inscrita no CNPJ sob o nº 39.929.100/0001-32 e  CRC  nº  MG-015727/O,  com  sede  na  Rua  Paulo Diniz Carneiro, 645,  3º  andar, sala 302, Bairro Buritis, Belo Horizonte/MG (“CONTABILIDADE” ou “CONTRATADA”), apresenta os termos do contrato de prestação de serviços, o qual, uma vez lido e aceito pela CONTRATANTE, através do click no campo “Concordo e Aceito os
        Termos do Contrato” no site w ww.gestaumdigital.com.br (“Site”), passará a regular a relação entre
        a CONTRATANTE e a CONTRATADA (“Partes”), juntamente com a Política de Privacidade constante no site.
      </P>

      <P>Definições:</P>

      <Tab1>a.	Portal Gestaum – site e /ou sistema que consiste em um software, de titularidade própria ou de parceiros, utilizado para a coleta, armazenamento e gerenciamento das informações fornecidas pela CONTRATANTE à CONTRATADA.</Tab1>
      <Tab1>b.	Propriedade Intelectual - qualquer direito de patente, direito autoral, direito autoral moral, direito de marca, direitos sobre nome comercial, direito às marcas de serviços, direitos de imagem comercial, direitos sobre segredos comerciais; direitos sobre logos, designs, slogans e outros intangíveis genéricos de natureza assemelhada, softwares de computadores, programas e aplicações, informações exclusivas tangíveis e intangíveis, know-how, processos exclusivos, fórmulas, algoritmos, direitos de propriedade, direitos de privacidade e direitos de publicidade, tendo sido tais direitos submetidos,  registrados ou não sob a proteção de qualquer lei ou que estejam protegidos ou sejam protegíveis sob a lei aplicável.</Tab1>

      <Title1>1.	OBJETO</Title1>

      <P>1.1.	O presente Contrato tem como objeto regular o fornecimento, pela CONTRATADA à CONTRATANTE, dos seguintes serviços (“Serviços”):</P>

      <Tab1>a)	Serviço de abertura de empresas (se contratado), descrito na cláusula 3 e/ou;</Tab1>
      <Tab1>b)	Serviços mensais de assessoria contábil, administrativa e fiscal, descritos na cláusula 4;</Tab1>
      <Tab1>c)	Serviços adicionais prestados pela CONTRATADA ou por meio de parcerias;</Tab1>
      <Tab1>d)	Serviços de gestão digital com a contabilidade integrada.</Tab1>

      <P>1.2.	Todos os serviços prestados pela CONTRATADA serão realizados através do “Portal Gestaum”, após a realização de cadastro pela CONTRATANTE”, ressalvado o caso de contratação de atendimento diferenciado (telefone e WhasApp). </P>

      <P>1.3.	A CONTRATANTE irá realizar seu cadastro no Portal Gestaum, informando seus dados cadastrais e demais informações solicitadas.</P>

      <P>1.4.	A CONTABILIDADE será responsável pela coleta, armazenamento e processamento dos dados fornecidos pela CONTRATANTE à CONTRATADA, através do Portal Gestaum e outros procedimentos administrativos relacionados à prestação de serviços.</P>

      <Title1>2.	COMUNICAÇÃO E SEGURANÇA DAS INFORMAÇÕES</Title1>

      <P>2.1.	Toda comunicação entre as partes deverá ser realizada através do Portal Gestaum e/ou por e-mail, sendo o e-mail da CONTRATANTE será o fornecido por ela quando da realização do seu cadastro, salvo, contratação de atendimento diferenciado (telefone e WhatsApp).</P>

      <P>2.2.	A CONTRATANTE declara que todas as informações fornecidas à CONTRATADA são válidas e verdadeiras, comprometendo-se a atualizá-las sempre que houver mudança.</P>

      <P>2.3.	A CONTRATANTE autoriza a CONTRATADA acessar e fazer uso dessas informações para todos os fins previstos neste Contrato e na política de privacidade. A CONTRATADA, por sua vez, se comprometem a não compartilhar as informações da CONTRATANTE com terceiros e a fazer uso exclusivo dessas informações para prestação dos serviços objeto deste Contrato.</P>

      <P>2.4.	O Portal Gestaum será acessada por meio do usuário e senha criados pela CONTRATANTE, sendo que o acesso às informações disponibilizadas no Portal Gestaum deverá ser gerenciado exclusivamente pela CONTRATANTE que será a única  responsável pelas informações ali constantes e pelo acesso a elas. A CONTRATADA não  se responsabiliza pelo acesso das informações da CONTRATANTE por outras pessoas que não usuários habilitados no Portal Gestaum.</P>

      <P>2.5.	A CONTRATANTE é a única responsável pela assinatura e/ou confirmações de todo e qualquer termo disponível no Portal Gestaum.</P>

      <Title1>3.	SERVIÇO DE ABERTURA DE EMPRESAS</Title1>

      <P>3.1.	Todas as obrigações previstas na cláusula 3 se aplicam unicamente à CONTRATANTE dos serviços de ABERTURA DE EMPRESAS, que ainda não possuem CNPJ.</P>

      <P>3.2.	A CONTABILIDADE prestará à CONTRATANTE os seguintes serviços:</P>

      <Tab1>3.2.1.	CONSULTA EM ÓRGÃOS PÚBLICOS</Tab1>
      <Tab2>i.	Realização da consulta prévia de localização na Prefeitura;</Tab2>
      <Tab2>ii.	Realização da consulta de viabilidade de nome na Junta Comercial.</Tab2>
      <Tab1>3.2.2.	ELABORAÇÃO DOS DOCUMENTOS NECESSÁRIOS</Tab1>
      <Tab2>i.	             Contrato Social para empresas LTDA, SLU e EIRELI;</Tab2>
      <Tab2>ii.	             Requisição de Empresário para empresas de natureza jurídica Empresário Individual;</Tab2>
      <Tab2>iii.	Documento Básico de Entrada (DBE) na Receita Federal;</Tab2>
      <Tab2>iv.	 	    Demais documentos obrigatórios para registro na Junta Comercial e Prefeitura;</Tab2>
      <Tab2>v.	  	  Solicitação de Enquadramento no Simples Nacional (válido apenas para empresas que possuem atividades permitidas ao enquadramento).</Tab2>
      <Tab2>vi.	           Pré-cadastros da CONTRATANTE junto a parceiros para a prestação de SERVIÇOS ADICIONAIS PARCERIAS, independentemente da Contratação destes serviços.</Tab2>

      <Tab1>3.2.3.	DOS SERVIÇOS NÃO INCLUSOS</Tab1>
      <Tab2>i.	            Consulta de marcas e patentes no INPI (Instituto Nacional de Propriedade Intelectual);</Tab2>
      <Tab2>ii.	             Protocolo de documentos e processos na Junta Comercial;</Tab2>
      <Tab2>iii.	Protocolo de documentos e processos na Prefeitura;             </Tab2>
      <Tab2>iv.	Protocolo de documentos e processos na Receita Federal;</Tab2>
      <Tab2>v.	             Registro da empresa e/ou sócios em órgãos representativos de classe (exemplo: CRA, CRC, CREA, CRQ, OAB, etc);</Tab2>
      <Tab2>vi.	             Coordenação ou acompanhamento de vistorias e avaliações do Corpo de Bombeiros e demais órgãos;</Tab2>
      <Tab2>vii.	    Projeto técnico para obtenção do alvará;</Tab2>
      <Tab2>viii.	    Demais serviços ou atividades não descritas nos itens 3.2.1 e 3.2.2.</Tab2>

      <P>3.3.	A partir da aceitação e adesão ao presente Contrato, a CONTRATANTE compromete- se a:</P>

      <Tab1>a)	Enviar a documentação necessária para elaboração das consultas e documentos descritos na cláusula 3.2.2., cuja impressão será realizada pela CONTRATANTE;</Tab1>
      <Tab1>b)	Realizar o protocolo do processo de abertura de empresa e eventuais documentos nos órgãos públicos, salvo se houver prestação do serviço de protocolo disponível na localidade do CONTRATANTE;</Tab1>
      <Tab1>c)	Realizar o registro da empresa nos órgãos reguladores, caso necessário;</Tab1>
      <Tab1>d)	Respeitar todos os prazos indicados no Portal Gestaum ou e-mail para possibilitar a correta prestação dos serviços;</Tab1>
      <Tab1>e)	O CONTRATANTE se compromete a protocolar TODOS os documentos solicitados na forma, local e prazo indicados pela CONTABILIDADE;</Tab1>
      <Tab1>f)	A atender toda e qualquer exigência, sempre que necessário e solicitado por quaisquer órgãos públicos, dentro do prazo concedido pela autoridade competente.</Tab1>

      <P>3.4.	A CONTRATANTE ASSUME TODA E QUALQUER RESPONSABILIDADE PELO NOME DADO À EMPRESA (RAZÃO SOCIAL E NOME FANTASIA) E A DESCRIÇÃO DE SUAS ATIVIDADES. A mera sugestão de nome e/ou atividade pela CONTABILIDADE serão sempre aprovados previamente pelo CONTRATANTE. Entende-se por aprovação tácita a assinatura do contrato social.</P>

      <P>3.5.	Considerando que a abertura da empresa depende de aprovações da junta comercial, prefeitura e/ou outros órgãos, a CONTABILIDADE não é responsável pelos prazos utilizados por tais órgãos para aprovação do funcionamento da CONTRATANTE. Declara o CONTRATANTE que reconhece e isenta de responsabilidade a CONTRATADA por quaisquer danos – diretos ou indiretos - decorrentes de imposições de exigências por parte das autoridades públicas envolvidas no procedimento de abertura de empresas, que porventura atrasem a conclusão dos serviços ora contratados.</P>

      <P>3.6.	A CONTRATANTE RECONHECE QUE A ATIVIDADE EMPRESARIAL NÃO PODERÁ SER EXERCIDA ANTES DO REGISTRO, nos termos do artigo 966 do código civil. A CONTRATADA não se responsabiliza por quaisquer investimentos ou custos obtidos pela contratante para a realização da atividade antes da conclusão e aprovação do registro empresarial, tampouco por quaisquer perdas e danos diretos ou indiretos, lucros cessantes, reclamações de terceiros, custo de fornecimento de bens ou serviços substitutos, tampouco por custo com paralisações, ou qualquer fato fora de seu controle razoável.</P>

      <P>3.7.	A CONTRATANTE declara-se ciente de que a escrituração contábil deverá ser realizada desde a data da emissão do CNPJ, que poderá ser retroativa, tendo em vista a necessidade de atender a todas as normas contábeis de escrituração. Caso tenha havido a  contratação dos serviços mensais (cláusula 3) a cobrança do valor da mensalidade terá início na data da emissão do CNPJ, p odendo ser retroativa.</P>

      <P>3.8.	A CONTRATANTE pagará pelos  Serviços  de  Abertura  de  Empresa  o  valor  de  R$ 799,00 (setecentos e noventa e nove reais).</P>

      <Tab1>3.8.1.	Caso a CONTRATANTE contrate os serviços mensais (cláusula 4) pelo período mínimo de 12 (doze) meses, a CONTRATADA oferecerão desconto de 100% (cem por cento) nos Serviços de Abertura de Empresa (cláusula 3), porém deverá pagar, no início do processo de abertura, o valor de R$ 169,00 (cento e sessenta e nove reais) a ser convertido em crédito para pagamento de mensalidades dos serviços mensais, previstos na cláusula 3.</Tab1>
      <Tab1>3.8.2.	O valor pago como antecipação das mensalidades dos serviços mensais acima mencionado, não será devolvido caso a CONTRATANTE rescinda o presente contrato antes de decorrido o prazo para a aproveitamento desse crédito, sem prejuízo da multa rescisória prevista na cláusula 4.9.</Tab1>

      <P>3.9.	VIGÊNCIA - A relação contratual entre a CONTRATANTE e CONTABILIDADE, especificamente para os serviços de abertura de empresas terá vigência de 6 (seis) meses a contar a data de aceite do presente contrato junto ao Portal Gestaum.</P>

      <Tab1>3.9.1.	Caso o processo de abertura tenha de ser reiniciado, ainda dentro do período de vigência, por solicitação da CONTRATANTE após o início da prestação dos serviços, por prazo vencido nos órgãos públicos ou por qualquer outra razão que esteja fora do controle da CONTABILIDADE, será cobrada taxa administrativa de R$ 99,00 (noventa e nove reais).</Tab1>

      <Tab1>3.9.2.	NÃO HAVERÁ SUSPENSÃO DA VIGÊNCIA DESTE CONTRATO CASO CONTRATANTE MANTENHA-SE INERTE EM RELAÇÃO À S SUAS OBRIGAÇÕES NECESSÁRIAS PARA A CONCLUSÃO DA A BERTURA DA EMPRESA (envio de documentos, protocolos, diligências junto a cartórios, pagamento de taxas, etc.).</Tab1>

      <Tab1>3.9.3.	Após a vigência prevista acima, a continuidade do procedimento de abertura de empresa deverá ser objeto de nova contratação de serviço e pagamento das taxas administrativas.</Tab1>

      <P>3.10.	Caso ocorra a rescisão por solicitação da CONTRATANTE, esta estará sujeita ao pagamento de multa rescisória, nas seguintes condições:</P>

      <table>
        <thead>
          <tr>
            <td>Momento da Rescisão</td>
            <td>Valor da Multa</td>
            <td>Consequências</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Fase de consulta (Cláusula 3.2.1)</td>
            <td>R$ 69,00</td>
            <td>Estorno ao CONTRATANTE no valor de R$ 100,00</td>
          </tr>
          <tr>
            <td>Fase	de	Elaboração	de documentos (Cláusula 3.2.2)</td>
            <td>R$ 169,00</td>
            <td>Sem estorno ao CONTRATANTE</td>
          </tr>
          <tr>
            <td>CNPJ já emitido</td>
            <td>R$	169,00	+	Multa rescisória (cláusula 4.9)</td>
            <td>Sem estorno ao CONTRATANTE e não responsabilização pela DECLARAÇÃO SEM MOVIMENTO</td>
          </tr>
        </tbody>
      </table>

      <Title1>4.	SERVIÇOS MENSAIS DE ASSESSORIA CONTÁBIL, ADMINISTRATIVA E FISCAL</Title1>

      <P>A CONTABILIDADE prestará à CONTRATANTE os seguintes serviços de assessoria administrativa e fiscal (“s erviços administrativos e fiscais”):</P>

      <Tab1>4.1.1.	Elaboração de contratos de experiência de empregados;</Tab1>
      <Tab1>4.1.2.	Orientação da comunicação ao sindicato de admissão e demissão de empregados;</Tab1>
      <Tab1>4.1.3.	Processamento de folha de pagamento e recibo de pagamento;</Tab1>
      <Tab1>4.1.4.	Cálculo e recolhimento de FGTS e INSS;</Tab1>
      <Tab1>4.1.5.	Elaboração de documentos para homologação de rescisões trabalhistas;</Tab1>
      <Tab1>4.1.6.	Elaboração de recibos de férias;</Tab1>
      <Tab1>4.1.7.	Emissão de guia de contribuição sindical de empregado;</Tab1>
      <Tab1>4.1.8.	Emissão de comprovante de rendimento de empregado e empregador;</Tab1>
      <Tab1>4.1.9.	Assessoria de exigências previstas na legislação fiscal e trabalhista;</Tab1>
      <Tab1>4.1.10.	Cálculo e emissão de guias de impostos, pró-labore, transmissão das obrigações acessórias como, por exemplo, DCTF, SPED e demais obrigatórias por lei;</Tab1>
      <Tab1>4.1.11.	Transmissão do IRPJ (DEFIS e ECD);</Tab1>
      <Tab1>4.1.12.	Outros procedimentos relacionados aos acima descritos e obrigatórios pela legislação brasileira.</Tab1>

      <P>4.2.	A CONTABILIDADE, por sua vez, prestará à CONTRATANTE os seguintes serviços de contabilidade referentes à escrituração fiscal e contábil obrigatória (“Serviços Contábeis”):</P>

      <Tab1>Escrituração Contábil: classificação da contabilidade de acordo com normas e princípios contábeis vigentes; emissão de balancetes; elaboração de balanço anual e demais demonstrações contábeis obrigatórias; </Tab1>
      <Tab1>4.2.2.	Escrituração Fiscal: escrituração dos registros fiscais de todos os livros obrigatórios perante o governo federal e municipal;</Tab1>
      <Tab1>4.2.3.	Atendimento das demais exigências previstas na legislação brasileira e que sejam serviços privativos de Contador.</Tab1>

      <P>4.3.	As CONTRATANTES prestarão os mesmos serviços descritos neste contrato para empresas de COMÉRCIO desde que sejam ( i) OPTANTES PELO SIMPLES NACIONAL ( ii) EMITAM NOTAS FISCAIS ELETRÔNICAS (NFC-e e NF-e) e (iii) TENHAM A INSCRIÇÃO ESTADUAL ATIVA. CASO ALGUMA DESSAS CONDIÇÕES SEJA ALTERADA	DURANTE	A	EXECUÇÃO	DO	CONTRATO,	A	CONTRATADA RESERVA-SE	O	DIREITO	DE	RESCINDIR	O	PRESENTE	CONTRATO, INDEPENDENTE DE NOTIFICAÇÃO.</P>

      <P>4.4.	Além dos Serviços descritos nas Cláusulas 4.1 e 4.2, a CONTRATANTE poderá
        contratar  S ERVIÇOS  ADICIONAIS  (extraordinários),  conforme  descritivo  no  ANEXO   I
        (SERVIÇOS	ADICIONAIS	GESTAUM),	bem	como	SERVIÇOS	ADICIONAIS
        PARCERIAS, conforme rol de parcerias vigentes, através do Portal Gestaum e mediante pagamento de valor adicional, que será definido no momento da contratação.
      </P>
      <Tab1>4.4.1. As CONTRATADAS poderão celebrar parcerias para viabilizar, facilitar ou integrar SERVIÇOS ADICIONAIS para a CONTRATANTE (“SERVIÇOS ADICIONAIS PARCERIAS”), sendo necessário, nestes casos, que ocorra contratação direta com as parceiras para a ativação destes serviços, o que somente se confirmará mediante o aceite da CONTRATANTE aos Termos e Condições de Serviços ou Contrato da parceira.</Tab1>
      <Tab2>4.4.1.1.	Os SERVIÇOS ADICIONAIS PARCERIAS incluem, mas não se limitam ao portal de gestão, ferramenta “multi-cálculo”, planos de saúde, seguros de diversas modalidades, a abertura de contas bancárias e serviços bancários correlatos, consultoria de marketing digital, consultoria jurídica, a depender da disponibilidade e vigência das parcerias.

        Ao finalizar seu cadastro junto à CONTRATADA, a CONTRATANTE autoriza o compartilhamento de seus dados e informações necessárias junto às empresas parcerias para a facilitação da contratação dos SERVIÇOS ADICIONAIS PARCERIAS ou, ainda, para viabilizar a integração destes serviços ao Portal da Gestaum, incluindo, mas não se limitando a dados cadastrais, senhas, logins e informações contábeis.
      </Tab2>
      <Tab3>4.4.1.1.1.	Os dados compartilhados com empresas parcerias servirão para o preenchimento de pré-cadastro junto a estas empresas parceiras, estando a contratação definitiva dos SERVIÇOS ADICIONAIS PARCERIAS condicionada à contratação específica frente às parceiras.</Tab3>
      <Tab2>4.4.1.2.	A CONTRATANTE declara estar ciente que a responsabilidade da CONTRATADA está limitada aos SERVIÇOS e SERVIÇOS
        ADICIONAIS ofertados diretamente por si, sendo, portanto, de inteira e exclusiva responsabilidade da parceira responder por reclamações e danos sofrido por si ou terceiros que sejam decorrentes de qualquer falha na prestação do(s) SERVIÇO(S) ADICIONAL(AIS) PARCERIAS.
      </Tab2>
      <Tab2>4.4.1.3.	Os faturamentos correspondentes aos SERVIÇOS ADICIONAIS PARCERIAS serão emitidos e enviados à CONTRATANTE diretamente pelo parceiro, podendo a CONTRATADA, entretanto, incluir em suas faturas e boletos de cobrança os SERVIÇOS ADICIONAIS PARCERIAS, oportunidade na qual será responsável pelo repasse dos valores efetivamente negociados com o parceiro.</Tab2>

      <P>4.5.	A CONTRATANTE declara ciente que os serviços serão prestados de forma online
        através	do Portal	Gestaum.	E VENTUAL	NECESSIDADE	DE COMPARECIMENTO PESSOAL JUNTO A QUAISQUER ÓRGÃOS PÚBLICOS É DE RESPONSABILIDADE DA CONTRATANTE.
      </P>

      <P>4.6.	A CONTRATANTE declara ciente que a CONTRATADA NÃO PRESTA SERVIÇOS para as empresas:</P>
      <Tab1>a)	que tenham filiais ou venham a ter durante a execução do contrato;</Tab1>
      <Tab1>b)	que não estejam enquadradas no Simples Nacional;</Tab1>
      <Tab1>c)	de comércio que exerçam atividades de importação e exportação;</Tab1>
      <Tab1>d)	com mais de 10 (dez) funcionários;</Tab1>
      <Tab1>e)	cuja remuneração de funcionário seja computada em horas (horistas), diárias (diaristas), semana, puramente comissões ou contrato intermitente;</Tab1>
      <Tab1>f)	que realizem atividade industriais ou equiparadas à indústria;</Tab1>
      <Tab1>g)	que atuem com regime de caixa.</Tab1>

      <Tab2>4.6.1.	Caso	qualquer	das	condições	acima	previstas	seja	identificada,	as CONTRATANTES reservam-se o direito de rescindir o presente contrato.</Tab2>

      <P>4.7.	VIGÊNCIA - A relação contratual entre a CONTRATANTE e CONTRATADA, especificamente para os serviços mensais de assessoria contábil, administrativa e fiscal e processamento de dados terá vigência de 12 (doze) meses, e será renovado sucessivamente por iguais períodos, salvo manifestação em contrário das Partes, a contar:</P>

      <Tab1>(i)	da data de início de responsabilidade contábil indicado pela CONTRATANTE no momento da aprovação do cadastro da CONTRATANTE ou;</Tab1>
      <Tab1>(ii)	da data da emissão do CNPJ para empresas cujo serviço de abertura tenha sido prestado pela CONTRATADA, podendo, eventualmente ser retroativa.</Tab1>

      <P>4.8.	Ressalvado o disposto na cláusula 4.9, o presente contrato poderá ser resilido a qualquer tempo, por qualquer uma das Partes, mediante aviso prévio de 30 (trinta) dias da data em que a Parte desejar resilir o Contrato.</P>

      <Tab1>4.8.1.	Caso a intenção de resilição seja da CONTRATANTE, esta estará sujeita à cobrança de multa compensatória no valor equivalente a 1 (uma) parcela mensal do valor vigente à época. Serão, ainda, devidos pela CONTRATANTE todos os pagamentos vencidos e vincendos entre a data da comunicação do interesse em rescindir o Contrato até a data do efetivo distrato.</Tab1>

      <P>4.9.	Caso a resilição ocorra antes do 12º (décimo segundo) mês seguinte à data de entrada em vigor do contrato (“ PRIMEIRO ANIVERSÁRIO”) ainda que decorrente de baixa da empresa, a CONTRATANTE deverá pagar à CONTRATADA uma multa em valor equivalente a 30% (trinta por cento) dos honorários vincendos entre a data da resilição e a data do primeiro aniversário.</P>

      <Tab1>4.9.1.	Caso a CONTRATANTE tenha sido beneficiada com qualquer condição promocional, como a antecipação de taxas administrativas (junta comercial, cartório, etc) e certificado digital gratuito, e a rescisão ocorra antes do 12º (décimo segundo) mês seguinte à data de entrada em vigor do contrato (“PRIMEIRO ANIVERSÁRIO”),  além  da  multa  prevista  na  cláusula  4.9, deverá reembolsar à CONTRATADA os valores referentes a essa antecipação e à disponibilização do certificado digital.</Tab1>

      <P>4.10.	Caso a CONTRATANTE realize a mudança de plano de atendimento para outro de menor valor (DOWNGRADE), antes de completados 6 (seis) meses da opção pelo plano de maior valor, e a CONTRATANTE tenha sido beneficiada com qualquer condição promocional, como a antecipação de taxas administrativas (junta comercial, cartório, etc) e certificado digital gratuito, deverá reembolsar à CONTRATADA os valores referentes a essa antecipação e à disponibilização do certificado digital.</P>

      <P>4.11.	O Contrato poderá ser rescindido de pleno direito pela CONTRATADA caso a CONTRATANTE pratique atos contrários à legislação e à boa-fé contratual, incluindo mas não se limitando à tentativa e(ou) concretização de evasão fiscal (sonegação), falsificação de documentos, tentativa e (ou) concretização de pagamentos e(ou) oferta de vantagens a agentes públicos com o intuito de obtenção de vantagem ilícita, oferta ou concretização de pagamentos e(ou) oferta de vantagens a funcionários da CONTRATADA para que pratiquem atos contrários ás políticas internas e (ou) para que infrinjam leis, regulamentos, normativas, instruções e semelhantes independentemente de qualquer notificação.</P>

      <P>4.12.	O Contrato poderá, ainda, ser rescindido de pleno direito pela CONTRATADA ante  o inadimplemento de obrigações contratuais por parte da CONTRATANTE independentemente de qualquer notificação.</P>

      <P>4.13.	A CONTRATANTE pagará pelos serviços mensais de assessoria contábil, administrativa e fiscal da CONTRATADA os valores definidos no plano escolhido pela CONTRATADA junto ao Portal Gestaum, documento que passará a integrar o presente contrato.</P>

      <P>4.14.	A CONTRATANTE declara ciente que OS PLANOS A SEREM ESCOLHIDOS POR ELA TERÃO VALORES VARIÁVEIS a depender dos seguintes critérios:</P>

      <Tab1>a)	Forma de pagamento mensal, semestral ou anual;</Tab1>
      <Tab1>b)	O fato de ser empresa prestadora de serviços e/ou ter atividades de comércio;</Tab1>
      <Tab1>c)	Regime tributário;</Tab1>
      <Tab1>d)	Quantidade de sócios;</Tab1>
      <Tab1>e)	Quantidade de funcionários;</Tab1>
      <Tab1>f)	Faturamento;</Tab1>
      <Tab1>g)	Local da sede da CONTRATANTE;</Tab1>
      <Tab1>h)	Forma de Comunicação e/ou Atendimento;</Tab1>
      <Tab1>i)	Volume notas fiscais emitidas no mês;</Tab1>
      <Tab1>j)	Volume de boletos bancários emitidos no mês;</Tab1>
      <Tab1>k)	Entre outros, previamente comunicados à CONTRATANTE.</Tab1>

      <Tab2>4.14.1.	A mudança de qualquer condição da CONTRATANTE que afete a formação dos honorários conforme critérios previstos na cláusula acima e no plano escolhido no momento da assinatura do contrato, alterará também o valor da presente prestação de serviço. A CONTRATANTE DECLARA CIÊNCIA ACERCA DA POSSIBILIDADE DE TAL MUDANÇA, A QUAL SERÁ INFORMADA PREVIAMENTE JUNTO AO PORTAL GESTAUM.</Tab2>

      <P>4.15.	O valor a ser pago pela CONTRATANTE poderá será proporcionalmente direcionado à outra empresa da Gestaum e Contabilidade Digital em contrapartida à prestação dos Serviços Administrativos e Fiscais e processamento de dados por ela prestados e à CONTABILIDADE em contrapartida à prestação dos serviços privativos de profissionais da área contábil.</P>

      <P>4.16.	A CONTRATADA emitirá mensalmente à CONTRATANTE notas fiscais com a descrição dos serviços prestados e com o valor devido pela CONTRATANTE, que será pago através de boleto bancário ou cartão de crédito, conforme a escolha da CONTRATANTE, até o 10º (décimo quinto) dia de cada mês. Os pagamentos mensais serão feitos sempre de forma adiantada e serão referentes aos serviços a serem prestados no mês seguinte àquele em que o pagamento foi feito.</P>

      <P>4.17.	A CONTRATANTE concorda que a CONTRATADA pode terceirizar o processo de cobrança do valor mensal e (ou) ceder créditos, obrigações e direitos decorrentes deste contrato sem, contudo, transferir à CONTRATANTE os custos pelos serviços de cobranças. Neste sentido, poderá a CONTRATADA transferir informações fornecidas ou relacionadas à CONTRATANTE à empresa terceira de cobrança ou cessionária, unicamente com o fim ora previsto, sem prejuízo das previsões deste contrato e política de privacidade.</P>

      <P>4.18.	Os honorários da CONTRATADA poderão ser reajustados anualmente, tendo como base a data de início de vigência, de acordo com a variação positiva acumulada pelo índice IGP- DI/FGV ou, na ausência dele, por qualquer outro que venha a substituí-lo e que traduza uma correção adequada dos valores contratados.</P>

      <P>4.19.	Em caso de descontinuidade do plano escolhido pela CONTRATANTE, a CONTRATADA informará os novos planos existentes para que a CONTRATANTE, no prazo de 30 (trinta) dias, possa fazer a nova opção de plano ou solicitar a rescisão do presente contrato.</P>

      <P>4.20.	A inatividade da CONTRATANTE não rescinde automaticamente o Contrato nem cessa a obrigação de pagamento, tendo em vista que serão mantidas as obrigações da CONTRATANTE perante os órgãos municipal, estadual e federal e a CONTRATADA continuará a prestar à CONTRATANTE os serviços para mantê-la regular. Caso a CONTRATANTE não tenha a intenção de prosseguir com a prestação de serviços deverá comunicar a CONTRATADA, requerendo a rescisão do presente contrato.</P>

      <P>4.21.	A CONTRATANTE reconhece e concorda que o não pagamento pelos serviços mensais configura causa de rescisão contratual, gerando à CONTRATADA o direito de rescindir o presente contrato, sem prejuízo da multa rescisória.</P>

      <Tab1>4.21.1.	O não pagamento de 2 (duas) mensalidades consecutivas gera RESCISÃO AUTOMÁTICA do presente contrato, independente de notificação. A CONTRATADA não responde por qualquer prejuízo gerado pelo período de inadimplência, bem como a contabilidade não permanecerá com a responsabilidade técnica pelos serviços contábeis em período de inadimplência.</Tab1>

      <Tab1>4.21.2.	Caso a CONTRATANTE pretenda realizar novo contrato de prestação de serviços, após a rescisão, este poderá ser firmado de acordo com os novos valores e planos de pagamento vigentes à época, e somente poderá ser firmado mediante regularização dos períodos em que a CONTRATADA ficou sem contador responsável. A CONTRATANTE declara-se ciente que os valores de regularização são referentes à prestação de serviços e não contemplam possíveis multas decorrentes da não realização de obrigações acessórias pelo período em que a CONTRATANTE se manteve sem contador responsável.</Tab1>

      <Tab1>4.21.3.	Para os casos de reincidência de rescisão por motivo de inadimplência CONTRATANTE, o retorno ao Portal Gestaum e contratação dos serviços dar-se-á apenas mediante pagamento integral do plano anual.</Tab1>

      <P>4.22.	A CONTRATANTE CONCORDA COM O ENVIO DE AVISO SOBRE ATRASO DE PAGAMENTO OU DESCUMPRIEMNTO DE OBRIGAÇÕES NÃO PECUNIÁRIAS VIA SMS OU OUTRO MEIO DE COMUNICAÇÃO QUE FACILITE A COBRANÇA DO PAGAMENTO OU DAS NÃO OBRIGAÇÕES NÃO PECUNIÁRIAS, SENDO REFERIDA COMUNICAÇÃO CONSIDERADA SUFICIENTE PARA CONSTITUIÇÃO DA CONTRATANTE EM MORA.</P>

      <P>4.23.	O não pagamento do valor mensal tornará a parte CONTRATANTE inadimplente. Os pagamentos efetuados após as datas de vencimento serão acrescidos de multa moratória de 10%
        (dez por cento) e juros de mora de 0,033% (zero vírgula zero trinta e três por cento) por dia de atraso.
      </P>

      <Title1>5.	OBRIGAÇÕES DA CONTRATANTE</Title1>

      <P>5.1.	Caso a CONTRATANTE já possua CNPJ, deverá enviar todos os documentos e informações necessários ao início da prestação dos serviços, inclusive coletar todos os documentos físicos e digitais com o contador responsável pelas competências anteriores e enviar em formato digital à CONTRATADA, no prazo de até 60 (sessenta) dias da adesão ao presente Contrato.</P>

      <Tab1>5.1.1.	A responsabilidade técnica da CONTABILIDADE terá início quando do recebimento de todos os documentos solicitados, em conformidade com orientação da CONTRATADA.</Tab1>
      <Tab1>5.1.2.	NA FALTA DO ENVIO DOS DOCUMENTOS, a CONTRATADA
        p oderá regularizar a contabilidade da CONTRATANTE referente a pperíodo anterior à presente contratação, mediante solicitação de serviços
        a dicionais,	através	do Portal	Gestaum	ou	e-mail	da
        C ONTRATADA.
      </Tab1>
      <Tab1>		NÃO	ENVIO	DOS	DOCUMENTOS	NO	PRAZO	GERA	A
        P OSSIBILIDADE DE RESCISÃO DO PRESENTE CONTRATO COM
        A PLICAÇÃO DE MULTA EQUIVALENTE AO VALOR DA PRIMEIRA MMENSALIDADE.
      </Tab1>

      <P>5.2.	Durante a prestação dos serviços descritos na cláusula 4, a CONTRATANTE compromete-se a:</P>

      <Tab1>5.2.1.	ADQUIRIR	CERTIFICADO	DIGITAL	MODELO	A1 PARA POSSIBILITAR    OS    SERVIÇOS    DA CONTRATADA.  A	falta   de
        c ertificado NO MODELO A1 impossibilita a prestação de serviços c ontábeis  e,  consequentemente,  impede  a  entrega  de  declarações. Dessa forma, as multas geradas pela ausência de entrega de declarações em razão da falta de certificado digital não serão de responsabilidade da CONTRATADA e não gera à CONTRATANTE o direito à restituição/indenização de qualquer valor;
      </Tab1>

      <Tab1>5.2.2.	Providenciar, MENSALMENTE OU QUANDO SOLICITADO PELA
        CCONTABILIDADE, a documentação fiscal-contábil descrita abaixo, que deverá
        ser	digitalizada	e	encaminhada	pelo Portal	Gestaum, IMPRETERIVELMENTE, ATÉ O 3 º (TERCEIRO)DIA ÚTIL DE CADA MÊS:
      </Tab1>

      <Tab2>a)	Emitir suas Notas Fiscais Eletrônicas por meio do Portal Gestaum;</Tab2>

      <Tab2>b)	Importar para o Portal Gestaum o extrato bancário mensal, no formato ofx;</Tab2>

      <Tab2>c)	Encaminhar, através do Portal Gestaum, os comprovantes de pagamentos de impostos e demais despesas.</Tab2>

      <Tab1>5.2.3.	A indisponibilidade do Portal Gestaum não exclui a responsabilidade da CONTRATANTE de enviar os documentos, devendo os mesmos serem remetidos para o e-mail contato@gestaumdigital.com.br. A utilização do e-mail será apenas em casos excepcionais de indisponibilidade do Portal Gestaum.</Tab1>

      <Tab1>5.2.4.	Atender a toda solicitação da CONTRATADA acerca de documentos e informações referentes a movimentações financeiras que possam impactar nas informações contábeis. Os profissionais da CONTRATADA não responderão tecnicamente pela escrituração gerada quando ausente qualquer informação financeira solicitada pela CONTRATADA.</Tab1>

      <Tab1>5.2.5.	Fornecer os usuários e senhas de acesso da CONTRATANTE nos sistemas da Prefeitura e Receita Federal e do Certificado Digital para a transmissão das informações contábeis da empresa junto aos sistemas dos respectivos órgãos.</Tab1>

      <Tab1>5.2.6.	Respeitar todos os prazos indicados no Portal Gestaum e e-mail enviados pela CONTRATADA para viabilizar a correta prestação dos serviços contábeis ou pagar os valores referentes a processos de “reabertura” de prestação de serviços pelo não cumprimento de prazo.</Tab1>

      <Tab1>5.2.7.	Informar todo início de mês os pagamentos recebidos, impostos e tributos pagos e notas fiscais emitidas;</Tab1>

      <Tab1>5.2.8.	Fornecer, antes do encerramento de cada exercício social, Carta de Responsabilidade da Administração, conforme artigo 2º da Resolução n.º 987/03 do Conselho Federal de Contabilidade, a qual será disponibilizada junto ao Portal Gestaum para leitura e aceite;</Tab1>

      <Tab1>5.2.9.	Acessar mensalmente o Portal Gestaum para verificação das informações e comunicados referentes à empresa, bem como cumprir com todos os requisitos dos termos assinados junto ao Portal Gestaum;</Tab1>

      <Tab1>5.2.10.	Realizar junto ao Portal Gestaum o processamento de folhas de pagamento de funcionários. O presente contrato poderá ser rescindido caso o processamento de folha de salário não seja realizado pela CONTRATADA.</Tab1>

      <Tab1>5.2.11.	Responsabilizar-se pelo correto arquivamento de todos os documentos, incluindo comprovantes de despesas e notas fiscais recebidas.</Tab1>

      <Title1>6.	RESPONSABILIDADE DE ADMINISTRAÇÃO DA CONTRATANTE</Title1>

      <P>6.1.	No ato da aceitação e adesão ao presente Contrato, o representante legal da CONTRATANTE declara que as informações que serão fornecidas para escrituração e elaboração das demonstrações contábeis, obrigações acessórias, apuração de tributos e arquivos eletrônicos exigidos pela fiscalização federal, estadual, municipal, trabalhista e previdenciária são fidedignas.</P>

      <P>6.2.	A CONTRATANTE declara que:</P>

      <Tab1>a)	Os controles internos adotados são de responsabilidade da administração e estão adequados ao tipo de atividade e volume de transações;</Tab1>
      <Tab1>b)	que não realiza nenhum tipo de operação que possa ser considerada ilegal, frente à legislação vigente;</Tab1>
      <Tab1>c)	que todos os documentos e/ou informações que gera e recebe de seus fornecedores, encaminhados para a elaboração da escrituração contábil e demais serviços contratados, estão revestidos de total idoneidade;</Tab1>
      <Tab1>d)	que os estoques registrados em conta própria são contados e levantados fisicamente e avaliados de acordo com a política de mensuração de estoque determinada pela empresa e perfazem a realidade;</Tab1>
      <Tab1>e)	que as informações registradas no sistema de gestão e controle interno são controladas e validadas com documentação suporte adequada, sendo de sua inteira responsabilidade todo o conteúdo do banco de dados e arquivos eletrônicos gerados.</Tab1>
      <Tab1>f)	que não houve qualquer fraude ou violação de leis, normas ou regulamentos cujos efeitos deveriam ser considerados para divulgação nas demonstrações contábeis, ou mesmo dar origem ao registro de provisão para contingências passivas.</Tab1>
      <Tab1>g)	está ciente que é de sua responsabilidade a emissão da Notas Fiscais, bem como a manutenção e atualização de seu cadastro junto às prefeituras municipais e no Portal Gestaum – quando e se aplicável.</Tab1>
      <Tab1>h)	Autoriza a CONTRATADA fazer o armazenamento e a alteração (quando necessária) de suas senhas, logins e cadastros junto aos órgãos públicos, em especial, mas não se limitando às prefeituras municipais, sem que haja qualquer alteração na condição da CONTRATANTE de responsável pela emissão de seus faturamentos, conforme delimitado na alínea “g” desta cláusula.</Tab1>

      <Title1>7.	LICENÇA</Title1>

      <P>7.1.	A CONTRATANTE reconhece que o Portal Gestaum e o material disposto on-line que o acompanha (“Documentação”), e quaisquer modificações, atualizações e novas versões são protegidos pela legislação de direitos autorais e demais direitos de propriedade intelectual e de software.</P>

      <P>7.2.	A CONTABILIDADE, na qualidade de legítima licenciadora do Portal Gestaum e “Documentação”, concede à CONTRATANTE o direito temporário, gratuito, não exclusivo, limitado e intransferível de uso do Portal Gestaum, de acordo com os termos e condições contidos neste Contrato, ressalvados quaisquer direitos, incluindo os direitos autorais e outros direitos de propriedade intelectual relacionados ao Portal Gestaum e à Documentação.</P>

      <P>7.3.	O uso do Portal Gestaum ficará restrito ao endereço da CONTRATANTE cadastrado no Site e a quantidade de usuários e/ou acessos estará restrita àquela quantidade informada no momento da contratação dos Serviços.</P>

      <P>7.4.	Pelo presente Contrato, a CONTRATANTE não adquire quaisquer direitos sobre o Portal Gestaum, além daqueles expressamente previstos neste Contrato, sendo expressamente vedado à CONTRATANTE, que também não poderá permitir que terceiros o façam:</P>

      <Tab1>a)	transferir, comercializar, sublicenciar, emprestar, alugar, arrendar ou de qualquer outra forma alienar o Portal Gestaum, salvo autorização expressa da CONTABILIDADE;</Tab1>
      <Tab1>b)	efetuar modificações, acréscimos ou derivações do Portal Gestaum, por si própria ou através da contratação de terceiros;</Tab1>
      <Tab1>c)	fazer engenharia reversa, descompilar ou desmontar o Portal Gestaum, ou tomar qualquer outra medida que possibilite o acesso ao código fonte do mesmo, sem o consentimento prévio e por escrito da CONTABILIDADE; e</Tab1>
      <Tab1>d)	copiar, total ou parcialmente, o Portal Gestaum, ou sua Documentação, ou usar de modo diverso do expressamente estipulado no presente Contrato.</Tab1>

      <P>7.5.	A introdução, pela CONTRATANTE, de quaisquer modificações ou alterações no Portal Gestaum, acarretará a exoneração da CONTABILIDADE de qualquer responsabilidade pelo Portal Gestaum, podendo a CONTABILIDADE rescindir este Contrato.</P>

      <P>7.6.	Na máxima medida permitida pela legislação aplicável, a CONTABILIDADE não oferecerá garantias e condições além daquelas identificadas expressamente neste Contrato, sejam  garantias ou condições de não violação de direitos, de adequação do Portal Gestaum ou dos serviços para o comércio e/ou para fins determinados.</P>

      <P>7.7.	A CONTABILIDADE garante que o Portal Gestaum funcionará de acordo com a Documentação fornecida à CONTRATANTE. Esta garantia não se aplicará:</P>

      <Tab1>a)	Se uma falha no Portal Gestaum resultar de acidente, violação, mau uso ou de culpa exclusiva da CONTRATANTE ou de terceiro;</Tab1>
      <Tab1>b)	Na ocorrência de problemas, erros ou danos causados por uso concomitante de outros softwares que não tenham sido licenciados ou desenvolvidos pela CONTABILIDADE; e</Tab1>
      <Tab1>c)	Em	decorrência	de	qualquer	descumprimento	do	presente	Contrato	pela CONTRATANTE.</Tab1>

      <P>7.8.	A CONTRATANTE concorda e reconhece que o funcionamento adequado do Portal Gestaum dependerá de sistemas operacionais e de infraestrutura, tais como acesso à Internet e serviços de telecomunicações, fornecidos por terceiros, cuja responsabilidade de contratação é exclusiva da CONTRATANTE, estando a CONTABILIDADE isenta de  qualquer responsabilidade pelo funcionamento inadequado do Portal Gestaum em razão de problemas decorrentes de tais sistemas operacionais e infraestrutura fornecidos por terceiros, inclusive equipamentos da CONTRATANTE ou NAVEGADORES DE INTERNET.</P>

      <P>7.9.	A CONTABILIDADE envidará seus melhores esforços para resolver, interromper ou cessar, problemas decorrentes de modificações, acréscimos, atualizações, customizações e  novas versões do Portal Gestaum. A CONTRATANTE, entretanto, concorda e reconhece que eventuais problemas no Portal Gestaum decorrentes de ataques de programas de terceiros ao Portal Gestaum, com ou sem o conhecimento/consentimento da CONTRATANTE, incluindo os referentes a vírus, não serão de responsabilidade da CONTABILIDADE.</P>

      <P>7.10.	A CONTABILIDADE NÃO SERÁ RESPONSÁVEL POR QUAISQUER PERDAS E DANOS INDIRETOS, DE QUALQUER NATUREZA, INCLUSIVE LUCROS CESSANTES DELES DECORRENTES, RECLAMAÇÕES DE TERCEIROS, CUSTO DE FORNECIMENTO DE BENS SUBSTITUTOS, DE SERVIÇOS OU TECNOLOGIA ALTERNATIVOS, CUSTO COM PARALISAÇÕES. A responsabilidade total da CONTABILIDADE por danos de qualquer espécie decorrentes da licença e do fornecimento do Portal Gestaum e da Documentação, limitar-se-á aos danos diretos efetivamente incorridos pela CONTRATANTE e em nenhum caso excederá a quantia paga pela CONTRATANTE no âmbito deste Contrato.</P>

      <Title1>8.	DISPOSIÇÕES GERAIS</Title1>

      <P>8.1.	A CONTRATADA RESERVA-SE O DIREITO DE REALIZAR, A QUALQUER TEMPO E SEM NECESSIDADE DE NOTIFICAÇÃO PRÉVIA, A RESCISÃO PELO NÃO CUMPRIMENTO DE TODA E QUALQUER CLÁUSULA DO PRESENTE CONTRATO, INCLUSIVE POR INDÍCIOS DE ILEGALIDADE NA ATUAÇÃO EMPRESARIAL E QUE IMPEÇAM A ATUAÇÃO DO CONTADOR DE ACORDO COM AS NORMAS GERAIS DE CONTABILIDADE.</P>

      <P>8.2.	Em caso de rescisão deste Contrato os documentos e Livros Contábeis da CONTRATANTE serão a ela disponibilizados para entrega ao novo responsável técnico, e será da CONTRATANTE a responsabilidade de entrega de tais documentos, não persistindo a responsabilidade técnica aos profissionais da CONTABILIDADE a partir da data da rescisão, nos termos da Resolução CFC 987/2003.</P>

      <P>8.3.	As eventuais multas decorrentes da entrega de declarações fora do prazo legal e pagamento de impostos atrasados, que forem decorrentes da não execução dos serviços por parte da CONTRATADA, serão de responsabilidade desta.</P>

      <P>8.4.	A CONTRATADA fica isenta do pagamento da multa prevista na Cláusula acima, caso a mesma seja decorrente de atraso na entrega de documentos e declarações por parte da CONTRATANTE ou entidades e pessoas externas que não sejam empregados ou prestadores de serviços da CONTRATADA, bem como nos casos em que não houver tempo hábil para a execução dos serviços e/ou a CONTRATADA não estiver de posse das informações e documentações necessárias para realização da atividade, considerando tempo hábil o prazo mínimo de 3 (três) dias úteis.</P>

      <P>8.5.	A CONTRATADA não se responsabiliza pelos entendimentos/interpretações que possam ser dados por qualquer conselho representativo de classe ou órgão regulatório quanto às atividades exercidas pela CONTRATANTE.</P>

      <P>8.6.	Todos os valores gastos com materiais para execução de serviços, tais como livros, correios, carimbos, pastas de arquivos, CDS, cópias e etc. serão antecipados pela CONTRATADA e reembolsados pela CONTRATANTE, mediante apresentação dos respectivos comprovantes.</P>

      <P>8.7.	A CONTRATANTE é responsável pelo pagamento de toda e qualquer taxa a ser paga perante todo e qualquer órgão sempre que necessário para o regular funcionamento da empresa.</P>

      <P>8.8.	As DILIGÊNCIAS JUNTO A CARTÓRIOS E ÓRGÃOS PÚBLICOS NÃO ESTÃO INCLUSAS NO PRESENTE CONTRATO e poderão ser eventualmente contratadas separadamente, mediante solicitação da CONTRATANTE, e consulta de disponibilidade do serviço na localidade pretendida.</P>

      <P>8.9.	Fica eleito o foro da Comarca de Belo Horizonte, Estado de Minas Gerais, para dirimir quaisquer dúvidas ou litígios decorrentes deste Contrato, renunciando expressamente a qualquer outro, mesmo que privilegiado.</P>

      <P>E, por estarem de pleno acordo, justas e contratadas, após terem LIDO e ACEITO o presente Contrato, nada tendo a OPOR ou RESSALVAR, as Partes firmam o presente confirmando por meio de aceite eletrônico da CONTRATANTE. O link para download da versão eletrônica em formato PDF será enviada para o e-mail do responsável legal após o aceite e cadastro dos dados da CONTRATANTE no sistema da CONTRATADA.</P>

      <Title1>ANEXO I - SERVIÇOS ADICIONAIS (GESTAUM)</Title1>

      <P>Além dos serviços mensais básicos incluídos no plano escolhido pela CONTRATANTE, poderão as empresas contratar, a título exemplificativo, os seguintes serviços adicionais, mediante o pagamento dos honorários, conforme tabela de preços vigente no momento da contratação, disponibilizada e atualizada no website da CONTRATADA1:</P>

      <table>
        <thead>
          <tr>
            <td>Nome</td>
            <td>Descrição</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Alteração apenas na Receita</td>
            <td>Este serviço compreende: Alteração de dados (ex.: telefone, e-mail de contato) na Receita Federal ou o nome fantasia da empresa
              (apenas em casos que o nome não está no contrato social). Veja mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Alteração contratual</td>
            <td>Este serviço compreende a alteração de dados da empresa tal como atualização de dados dos sócios, mudança endereço (dentro do município), alteração das atividades, entre outros. Veja mais detalhes.</td>
          </tr>
          <tr>
            <td>Alteração contratual</td>
            <td>Existem diversos tipos de alterações
              contratuais que variam de acordo com os itens que precisam ser alterados. Para entendermos melhor a sua necessidade e ajudá-lo a decidir qual a melhor opção criamos um guia. Acesse- o clicando em "SOLICITAR". Obs. 1: O prazo varia de acordo com o tipo de alteração
              contratual aplicável e você será informado(a) sobre isto antes de confirmar a contratação do serviço. Obs. 2: Não inclui a entrega presencial do processo nos órgãos
              competentes.
            </td>
          </tr>
          <tr>
            <td>Alteração contratual de 2 instâncias</td>
            <td>Este serviço compreende: Alteração de
              endereço entre cidades (no mesmo estado ou em estados diferentes) ou Alteração de tipo jurídico (Ex.: EI para LTDA, EI para EIRELI, e vice-versa). Veja mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Alteração de escrituração Contábil - Reabertura do Balanço</td>
            <td>Caso você precise importar uma nota fiscal, informar movimentação bancária, informar investimentos, ou tenha comprado produtos para sua empresa em nome de pessoa jurídica (Ativo imobilizado) ou qualquer movimentação necessária para complementar ou corrigir as informações do Balanço patrimonial referente ao ano anterior já fechado. Veja mais detalhes.</td>
          </tr>
          <tr>
            <td>Alteração na folha de pagamento</td>
            <td>Adicionar ou excluir informações na folha de pagamento de meses anteriores que já foram processados, por exemplo: Se sua empresa demitiu um funcionário e não foi avisado à Gestaum antes do fechamento da folha de pagamento. (lembrando que são apenas períodos que foram da nossa responsabilidade contábil). Veja quais alterações podem ser
              feitas com esse serviço em Mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Auxílio em emissão de documentos para licitação</td>
            <td>Auxílio na emissão de documentos e certidões para participar de licitações, concorrências públicas e editais em geral. Nós nos
              responsabilizamos por orientar apenas em documentações com características contábeis, por exemplo: Certidões negativas, balanço do último exercício social, comprovante de
              enquadramento de regime tributário entre outros. Veja Mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Baixa de empresa - para
              clientes com contrato ativo conosco há 6 meses ou mais
            </td>
            <td>Este serviço compreende o encerramento da sua empresa (caso não tenha intenção de utilizar este CNPJ futuramente). Inclui a
              elaboração da documentação na Junta Comercial, Receita Federal e Prefeitura. Veja mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Baixa de empresa - para
              clientes com contrato ativo conosco há menos de 6 meses
            </td>
            <td>Este serviço compreende o encerramento da sua empresa (caso não tenha intenção de utilizar este CNPJ futuramente). Inclui a
              elaboração da documentação na Junta Comercial, Receita Federal e Prefeitura. Veja mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Balanço de Abertura</td>
            <td>O Balanço de Abertura é uma simulação dos saldos que a empresa teria na data da migração para a Gestaum.</td>
          </tr>
          <tr>
            <td>Compensação de GPS</td>
            <td>Serviço para empresas cujos impostos foram pagos em duplicidade ou em valor maior.
              Realizamos a compensação nas próximas competências. Valor cobrado por guia.
            </td>
          </tr>
          <tr>
            <td>Comprovante de
              rendimentos do sócio (DECORE)
            </td>
            <td>Comprovante de rendimentos do sócio assinado por contador responsável e protocolado no Conselho Regional de Contabilidade para veracidade das informações. Veja mais detalhes.</td>
          </tr>
          <tr>
            <td>Consultoria tributária</td>
            <td>Nossos contadores vão analisar a sua empresa em relação a tributação atual e apresentar todas as opções de tributações, sempre
              respeitando a atividade prestada pela empresa. Ou seja, análise tributária da sua empresa, apontando as sugestões de melhoria. Veja mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Conversão de extratos (PDF para OFX)</td>
            <td>Conversão de extratos quando o banco não fornece o formato aceito pelo Portal Gestaum no período selecionado</td>
          </tr>
          <tr>
            <td>CPOM/CEPOM - Cadastro de Prestadores de Serviços de Outros Municípios</td>
            <td>Esse cadastro é para empresas que prestam serviços para outros municípios e são tributadas pelo ISS do seu município e também no município em que o serviço foi prestado. O CEPOM permite uma única tributação de ISS. Veja as cidades que
              fazemos esse cadastro em Mais detalhes
            </td>
          </tr>
          <tr>
            <td>Declaração de ausência de faturamento</td>
            <td>Caso sua empresa não tenha faturamentos, solicite a declaração de ausência de
              faturamento para os últimos 12 meses da empresa assinado pelo nosso contador
              responsável (para empresas que não tem 12 meses, será feito com a quantidade de meses em atividade). Veja Mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Declaração de faturamento</td>
            <td>Declaração de faturamento para os últimos 12 meses da empresa assinado pelo nosso
              contador responsável (para empresas que não tem 12 meses, será feito com a quantidade de meses em atividade). Veja quando você pode precisar desse serviço em Mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Declaração de previsão de faturamento</td>
            <td>Declaração de previsão faturamento para os últimos 12 meses da empresa assinado pelo nosso contador responsável (para empresas que não tem 12 meses, será feito com a quantidade de meses em atividade). Veja quando você pode precisar desse serviço em Mais detalhes.</td>
          </tr>
          <tr>
            <td>Demonstrações Contábeis</td>
            <td>Impressão e assinatura do Balancete, DRE e Balanço. Serviço válido para 2017. Enviamos o arquivo digital, caso exista a necessidade do envio físico, enviamos pelo valor de R$29,90
              + Sedex (valor não incluso no serviço, cobrado a parte).
            </td>
          </tr>
          <tr>
            <td>Demonstrações Contábeis / Índices de Liquidez</td>
            <td>Impressão e assinatura da DRE e Balanço. Além disso, é incluído o cálculo dos índices de liquidez. Veja quando você pode precisar desse serviço em Mais detalhes.</td>
          </tr>
          <tr>
            <td>DSPJ - Declaração de Inatividade da Pessoa
              Jurídica optante pelo Lucro Presumido
            </td>
            <td>Apenas para pessoas jurídicas que não tiveram movimento algum financeiro, contábil e fiscal. Este serviço é para apenas períodos anteriores ao ingresso na Gestaum.</td>
          </tr>
          <tr>
            <td>Emissão de Certidão Negativa (CND)</td>
            <td>Realizamos a emissão das Certidões negativas da sua empresa junto a órgãos Federais,
              Estaduais e Municipais. Certidões só podem ser emitidas para empresas que não possuam pendências nos respectivos órgãos. Caso a
              empresa tenha pendências junto a órgãos públicos, enviamos um orçamento de
              regularização. Veja Mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Emissão de Recibo de Pagamento Autônomo (RPA)</td>
            <td>Caso sua empresa tenha contratado um serviço de uma pessoa física, deverá ser gerado o RPA com as informações fornecidas e a geração das respectivas guias de impostos incidentes sobre esse tipo de pagamento. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>Enquadramento no Simples</td>
            <td>Solicitar o enquadramento no Simples. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>Enquadramento/Reenquadr amento ME/EPP</td>
            <td>Este serviço compreende a elaboração da documentação para o enquadramento ou
              reenquadramento da sua empresa em ME ou EPP. Veja mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Entrega de obrigações
              acessórias (DEFIS, DCTF, ECD, ECF)
            </td>
            <td>Entrega ou retificação das obrigações acessórias antes da competência da Gestaum. Apenas quando há
              documentação da contabilidade anterior.
            </td>
          </tr>
          <tr>
            <td>Formulários diversos
              (banco, fornecedores, etc)
            </td>
            <td>Preenchimento de formulários que necessitam de informações da parte contábil e assinatura pelo contador responsável. É necessário disponibilizar o formulário para preenchermos. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>GFIP</td>
            <td>Declaração enviada pelo contador para a Receita informando se há ou não movimento na folha de pagamento da empresa. Lembre-se de que a entrega de GFIP em atraso pode gerar multa pela Receita Federal. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>GFIP - Sem Movimento</td>
            <td>Entrega de GFIP sem movimento para
              regularização de pendências (valor cobrado por competência)
            </td>
          </tr>
          <tr>
            <td>Liberação de guia de parcelamento</td>
            <td>Entrega de guia de parcelamento para o cliente</td>
          </tr>
          <tr>
            <td>Liberação para emitir Notas Fiscais</td>
            <td>Esse serviço vai permitir que a sua empresa possa emitir notas fiscais. Para isso faremos a emissão de AIDF. Veja mais detalhes.</td>
          </tr>
          <tr>
            <td>
              Livros Contábeis
            </td>
            <td>Impressão e assinatura dos livros Razão, Diário, Balancete, DRE e Balanço de anos fechados. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>Obtenção de alvará</td>
            <td>Este serviço compreende a emissão do seu alvará na Prefeitura - caso ainda não tenha este documento. Veja mais detalhes.</td>
          </tr>
          <tr>
            <td>Parcelamento de Débitos - Lucro Presumido</td>
            <td>Solicitação e formalização de parcelamento de débitos do Lucro Presumido (ISS, CSLL,
              IRPJ, PIS, COFINS). Veja Mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Parcelamento de Débitos - Simples Nacional</td>
            <td>Solicitação e formalização de parcelamento de débitos do Simples Nacional (DAS ou GPS). Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>Procuração</td>
            <td>Elaboração de uma procuração, caso algum sócio da empresa precise se ausentar e queira deixar um responsável em seu lugar.</td>
          </tr>
          <tr>
            <td>Programa Especial de Regularização Tributária - Simples Nacional (PERT- SN)</td>
            <td>
              Simulação e adesão ao Programa Especial de Regularização Tributária (PERT-SN).
            </td>
          </tr>
          <tr>
            <td>RAIS Negativa</td>
            <td>A RAIS é a declaração enviada anualmente para a Receita informando se a empresa possui ou não movimento na folha de pagamento no ano. Use esse serviço para
              regularização de períodos anteriores a nossa responsabilidade. Para o período atual esse serviço já está incluso na sua mensalidade. Veja Mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Reabertura de Mês Contábil
              - Lucro Presumido
            </td>
            <td>Reabertura do mês contábil quando precisar alterar alguma nota ou importar alguma nota fiscal de meses anteriores na nosso Portal, caso sua empresa se enquadre no Lucro Presumido. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>Reabertura de Mês Contábil
              - Simples nacional
            </td>
            <td>Reabertura do mês contábil quando precisar alterar alguma nota ou importar alguma nota fiscal de meses anteriores na nosso Portal, caso sua empresa se enquadre no Simples nacional. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>ReDARF on-line</td>
            <td>Caso você tenha informado algum dado errado da guia de imposto do Lucro presumido
              (DARF) e realizou o pagamento. Nós faremos a retificação dessa guia de imposto para
              corrigir a informação para que o sistema da receita identifique este pagamento. Obs:
              Apenas para impostos do Lucro Presumido. Veja Mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Reemissão de guia de imposto do Lucro Presumido</td>
            <td>Reemissão de guias de impostos ISS, CSLL, IRPJ, PIS, COFINS e demais guias de imposto do lucro presumido de períodos
              anteriores a nossa competência ou de guias de parcelamento. Veja Mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Reemissão de guia de imposto do Simples Nacional</td>
            <td>Reemissão de guias de impostos DAS de períodos anteriores a nossa competência ou de guias de parcelamento. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>Reemissão de guia de imposto FGTS para períodos anteriores a nossa competência</td>
            <td>Caso tenha alguma guia de FGTS em atraso de algum mês anterior a Gestaum. Solicite a reemissão de guias de impostos FGTS. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>Reemissão de guia de imposto FGTS para períodos da nossa
              competência
            </td>
            <td>Caso tenha alguma guia de FGTS em atraso solicite a reemissão de guias de impostos FGTS. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>Reemissão de guia GPS (INSS)</td>
            <td>Reemissão de guias referentes ao INSS
              recolhido nos períodos de responsabilidade da Gestaum ou períodos anteriores. Neste item, estão incluídas guias de parcelamentos previdenciários.
            </td>
          </tr>
          <tr>
            <td>Regularização completa</td>
            <td>Este serviço é privativo do Contábil/CSA que fará a avaliação de regularizar a contabilidade nos períodos necessários.</td>
          </tr>
          <tr>
            <td>
              Regularização Completa LP
            </td>
            <td>Este serviço é privativo do Contábil/CSA que fará a avaliação de regularizar a contabilidade das empresas do Regime Lucro Presumido, nos períodos necessários.</td>
          </tr>
          <tr>
            <td>Regularização e Alteração somente na Prefeitura</td>
            <td>Este serviço compreende a alteração das informações da sua empresa cadastradas na Prefeitura, incluindo alteração no seu alvará. Veja mais detalhes.</td>
          </tr>
          <tr>
            <td>
              Regularização Fiscal
            </td>
            <td>Neste serviço regularizamos todas as pendências fiscais de sua empresa junto à Prefeitura, à Receita federal e o Estado. Veja Mais detalhes.</td>
          </tr>
          <tr>
            <td>Repasse pagamento de impostos</td>
            <td>Após o serviço de "Pagamento impostos
              clientes" nós cobraremos o valor da guia como repasse.
            </td>
          </tr>
          <tr>
            <td>Serviço de pagamento de impostos</td>
            <td>Neste serviço pagaremos as guias de impostos dos clientes e cobraremos por este repasse.</td>
          </tr>
          <tr>
            <td>Solicitação para habilitar Emissão de Fatura de Recebimento de Aluguel</td>
            <td>Solicite a habilitação da funcionalidade para sua empresa emitir fatura de cobrança de
              aluguel de imóveis próprios. Serviço gratuito.
            </td>
          </tr>
          <tr>
            <td>
              Verificação de pendências - Enquadramento SN
            </td>
            <td>Realizamos a verificação das pendências da sua empresa junto aos órgãos públicos e
              fornecemos o orçamento para as regularizações.
            </td>
          </tr>
          <tr>
            <td>
              Verificação de pendências - Orçamento
            </td>
            <td>Realizamos a verificação das pendências da sua empresa junto aos órgãos públicos e fornecemos o orçamento para as
              regularizações. Caso a empresa não tenha pendência, será emitido as Certidões negativas. Veja Mais detalhes.
            </td>
          </tr>
          <tr>
            <td>Regularização de Taxa de Localização e Funcionamento.	</td>
            <td>Realizamos a verificação de pendências da empresa junto a órgãos públicos e fornecemos a Taxa de Localização e Funcionamento para quitação e regularização.</td>
          </tr>
          <tr>
            <td>Obtenção de CNES</td>
            <td>Serviço para emissão do CNES. PS: Não inclui o protocolo físico do processo nos órgãos públicos (responsabilidade do sócio). Prazo: Até 30 dias.</td>
          </tr>
        </tbody>
      </table>

      <P>Os referidos serviços adicionais serão contratados diretamente no Portal Gestaum, mediante pagamento de valor adicional, que será definido no momento da contratação.</P>

      <P>Os serviços e valores descritos na tabela acima podem sofrer alterações de disponibilidade e/ou valores, e devem sempre ser consultados no momento da contratação adicional, diretamente no próprio Portal Gestaum.</P>

      <P>1	A	consulta	dos	honorários	vigentes para serviços esporádicos	poderão	ocorrer	através	do	Link:	https://gestaumdigital.com.br/servicos-esporadicos-e-valores-contratuais/</P>

    </div>
  );
}

export default ContratoV8;